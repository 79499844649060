import { StressStrainModel } from "../../models/analysis.stressStrain.model";
import { SET_STRESS_STRAIN_DATA_FILES, SET_STRESS_STRAIN_CIF_FILES, SET_STRESS_STRAIN_INSTRUMENTAL_FILE, SET_STRESS_STRAIN_WAVELENGTH, SET_STRESS_STRAIN_CIF_TEMPLATE_FILES, SET_STRESS_STRAIN_INSTRUMENTAL_TEMPLATE_FILE, SET_STRESS_STRAIN, SET_STRESS_STRAIN_INSTRUMENTAL_WAVELENGTH, SET_STRESS_STRAIN_DATA_VIEW_FILE, SET_STRESS_STRAIN_RESET, SET_STRESS_STRAIN_DATA_ACTIVE_FILES, SET_STRESS_STRAIN_PARTITIONS, SET_STRESS_STRAIN_ACTIVE_INDEX, SET_STRESS_STRAIN_SELECTING_PEAKS, SET_STRESS_STRAIN_HISTOGRAM, SET_STRESS_STRAIN_PARAMETERS, SET_STRESS_STRAIN_FITTING, SET_STRESS_STRAIN_CIF_FILES_ABC, SET_STRESS_STRAIN_PLANE_STRESS_CONDITION, SET_STRESS_STRAIN_ROTATION_X, SET_STRESS_STRAIN_ROTATION_Y, SET_STRESS_STRAIN_ROTATION_Z, SET_STRESS_STRAIN_STRESS_PEAKS, SET_STRESS_STRAIN_PARAMETERS_STRESS_BIN, SET_STRESS_STRAIN_PARAMETERS_RESULT } from "./stressStrainActions";

const initialState: StressStrainModel = {
  dataFiles: [],
  dataActiveFiles: [],
  dataViewFile: "",
  cifFiles: [],
  cifFilesABC: {},
  instrumentalFile: "",
  wavelength: 0,
  templateCifFiles: [],
  templateInstrumentalFile: "",
  instrumentalFileWaveLength: 0,
  partitions: [{
    name: "1",
    rangeHistory: [0, 0],
    rangeLower: {
      x: 0,
      y: 0
    },
    rangeHigher: {
      x: 0,
      y: 0
    },
    peaks: [],
  }],
  partition: {
    activeIndex: 0,
    selectingPeaks: false,
  },
  movingPeaks: false,
  fitting: {},
  histogram: {
    data: {
      x: [],
      y: []
    },
    phases: {}
  },
  partitionFitting: {},
  rotation: {
    x: 0,
    y: 0,
    z: 0
  },
  stressPeaks: [
    []
  ],
  planeStressCondition: true,
  stressFitting: {},
  stressResult: {
    frame_0001: {
      pv0: {
        strain_xx: 0.29571929703510963,
        'strain_xx: std_dev': 0.00008524920747129945,
        strain_xz: -0.00006536710151031404,
        'strain_xz: std_dev': 0.00006960567090491243,
        strain_zz: 0.29587218374560964,
        'strain_zz: std_dev': 0.00008524918883872533,
        'stress_xx [MPa]': 54688.74082827487,
        'stress_xx: std_dev [MPa]': 12.515323959108501,
        'stress_xz [MPa]': -6.171365323858954,
        'stress_xz: std_dev [MPa]': 6.571532373953212,
        'stress_zz [MPa]': 54703.17499656226,
        'stress_zz: std_dev [MPa]': 12.515321743431512,
        'von_mises [MPa]': 54695.95934085053,
        'von_mises: std_dev [MPa]': 11.146502544445603
      },
      pv1: {
        strain_xx: 0.0006090068901186741,
        'strain_xx: std_dev': 0.00007934694424684034,
        strain_xz: -0.000021893195611352042,
        'strain_xz: std_dev': 0.00006478650591971305,
        strain_zz: 0.0006415855202331063,
        'strain_zz: std_dev': 0.0000793469362583188,
        'stress_xx [MPa]': 114.08640978820128,
        'stress_xx: std_dev [MPa]': 11.64882059309133,
        'stress_xz [MPa]': -2.0669557790173756,
        'stress_xz: std_dev [MPa]': 6.116550785471397,
        'stress_zz [MPa]': 117.16218680051549,
        'stress_zz: std_dev [MPa]': 11.648819643142962,
        'von_mises [MPa]': 115.6549768027078,
        'von_mises: std_dev [MPa]': 10.374170884275255
      }
    },
    frame_0002: {
      pv0: {
        strain_xx: 0.2958620670932499,
        'strain_xx: std_dev': 0.00006984899159870237,
        strain_xz: 0.00010003130830647706,
        'strain_xz: std_dev': 0.00005703146601976767,
        strain_zz: 0.2957748175044351,
        'strain_zz: std_dev': 0.00006984900031019843,
        'stress_xx [MPa]': 54704.27440558672,
        'stress_xx: std_dev [MPa]': 10.254438868289956,
        'stress_xz [MPa]': 9.444043457937788,
        'stress_xz: std_dev [MPa]': 5.38439067407172,
        'stress_zz [MPa]': 54696.037095464475,
        'stress_zz: std_dev [MPa]': 10.254439904210235,
        'von_mises [MPa]': 54700.15621569757,
        'von_mises: std_dev [MPa]': 9.132895452332287
      },
      pv1: {
        strain_xx: 0.0005372542283422709,
        'strain_xx: std_dev': 0.00007765060029518315,
        strain_xz: -0.00001202764488182515,
        'strain_xz: std_dev': 0.00006340144051801766,
        strain_zz: 0.0006217803246877382,
        'strain_zz: std_dev': 0.00007765057994820378,
        'stress_xx [MPa]': 103.1691683045787,
        'stress_xx: std_dev [MPa]': 11.39978239243518,
        'stress_xz [MPa]': -1.1355404911088698,
        'stress_xz: std_dev [MPa]': 5.985785547395927,
        'stress_zz [MPa]': 111.14935111665076,
        'stress_zz: std_dev [MPa]': 11.399779972891118,
        'von_mises [MPa]': 107.38188594922583,
        'von_mises: std_dev [MPa]': 10.148385484044594
      }
    },
    frame_0027: {
      pv0: {
        strain_xx: 0.2958073152070924,
        'strain_xx: std_dev': 0.00007981581253342853,
        strain_xz: -0.000004859297200230472,
        'strain_xz: std_dev': 0.00006516933681328729,
        strain_zz: 0.29588746717145253,
        'strain_zz: std_dev': 0.00007981580338430433,
        'stress_xx [MPa]': 54701.725117237496,
        'stress_xx: std_dev [MPa]': 11.717654514446869,
        'stress_xz [MPa]': -0.458770506064055,
        'stress_xz: std_dev [MPa]': 6.152694185544494,
        'stress_zz [MPa]': 54709.29233441651,
        'stress_zz: std_dev [MPa]': 11.717653426486406,
        'von_mises [MPa]': 54705.50911835677,
        'von_mises: std_dev [MPa]': 10.436075991984783
      },
      pv1: {
        strain_xx: 0.0006291947205945511,
        'strain_xx: std_dev': 0.00008361745114059031,
        strain_xz: 0.00008085912685495614,
        'strain_xz: std_dev': 0.00006827337841129903,
        strain_zz: 0.0006100318120020856,
        'strain_zz: std_dev': 0.00008361745610395586,
        'stress_xx [MPa]': 115.47805249960152,
        'stress_xx: std_dev [MPa]': 12.275768283747269,
        'stress_xz [MPa]': 7.633981009720178,
        'stress_xz: std_dev [MPa]': 6.445749472365844,
        'stress_zz [MPa]': 113.66886551013158,
        'stress_zz: std_dev [MPa]': 12.275768873961738,
        'von_mises [MPa]': 114.5841715792892,
        'von_mises: std_dev [MPa]': 10.932925988173366
      }
    },
    frame_0028: {
      pv0: {
        strain_xx: 0.29586270255221886,
        'strain_xx: std_dev': 0.00007523250624006098,
        strain_xz: -0.00003241375368028613,
        'strain_xz: std_dev': 0.00006142710005881133,
        strain_zz: 0.29560456489868736,
        'strain_zz: std_dev': 0.0000752325340020619,
        'stress_xx [MPa]': 54696.65919285315,
        'stress_xx: std_dev [MPa]': 11.04478585008264,
        'stress_xz [MPa]': -3.060210883712814,
        'stress_xz: std_dev [MPa]': 5.799386334857564,
        'stress_zz [MPa]': 54672.28819082034,
        'stress_zz: std_dev [MPa]': 11.044789151377795,
        'von_mises [MPa]': 54684.47776483277,
        'von_mises: std_dev [MPa]': 9.836801825650237
      },
      pv1: {
        strain_xx: 0.0006634499135448137,
        'strain_xx: std_dev': 0.00008294030689101494,
        strain_xz: 0.000023557328825634753,
        'strain_xz: std_dev': 0.00006772048927908565,
        strain_zz: 0.0004989354348661164,
        'strain_zz: std_dev': 0.00008294034917424456,
        'stress_xx [MPa]': 115.23503761371695,
        'stress_xx: std_dev [MPa]': 12.176358146049022,
        'stress_xz [MPa]': 2.2240680537797157,
        'stress_xz: std_dev [MPa]': 6.393550724989204,
        'stress_zz [MPa]': 99.70308154510883,
        'stress_zz: std_dev [MPa]': 12.17636317412396,
        'von_mises [MPa]': 108.30757134831931,
        'von_mises: std_dev [MPa]': 10.826370005443474
      }
    },
  }
};

export default function stressStrainReducer(state = initialState, { type, payload }: { type: string; payload: any }): StressStrainModel {
  switch (type) {
    case SET_STRESS_STRAIN_DATA_FILES:
      return {
        ...state,
        dataFiles: payload,
      }
    case SET_STRESS_STRAIN_CIF_FILES:
      return {
        ...state,
        cifFiles: payload,
      }
    case SET_STRESS_STRAIN_INSTRUMENTAL_FILE:
      return {
        ...state,
        instrumentalFile: payload,
      }
    case SET_STRESS_STRAIN_WAVELENGTH:
      return {
        ...state,
        wavelength: payload,
      }
    case SET_STRESS_STRAIN_CIF_TEMPLATE_FILES:
      return {
        ...state,
        templateCifFiles: payload,
      }
    case SET_STRESS_STRAIN_INSTRUMENTAL_TEMPLATE_FILE:
      return {
        ...state,
        templateInstrumentalFile: payload,
      }
    case SET_STRESS_STRAIN:
      return {
        ...payload
      }
    case SET_STRESS_STRAIN_INSTRUMENTAL_WAVELENGTH:
      return {
        ...state,
        instrumentalFileWaveLength: payload,
      }
    case SET_STRESS_STRAIN_DATA_VIEW_FILE:
      return {
        ...state,
        dataViewFile: payload,
      }
    case SET_STRESS_STRAIN_RESET:
      return {
        ...initialState
      }
    case SET_STRESS_STRAIN_DATA_ACTIVE_FILES:
      return {
        ...state,
        dataActiveFiles: payload
      }
    case SET_STRESS_STRAIN_PARTITIONS:
      const newPartitions = [...payload];
      let updatedStressStrainPeaks = [...state.stressPeaks];

      // Loop through the new partitions and adjust the stressStrainPeaks dimensions
      newPartitions.forEach((partition, partitionIndex) => {
        // Ensure the stressStrainPeaks outer array has the correct number of partitions
        if (!updatedStressStrainPeaks[partitionIndex]) {
          updatedStressStrainPeaks[partitionIndex] = [];
        }

        // Adjust the inner array of peaks for this partition
        partition.peaks.forEach((peak, peakIndex) => {
          if (!updatedStressStrainPeaks[partitionIndex][peakIndex]) {
            // If there's no existing value, create a default StressStrainPeaks object
            updatedStressStrainPeaks[partitionIndex][peakIndex] = {
              youngsModulus: 0,
              poissonRatio: 0,
              tthRef: 0,
            };
          }
        });

        // If there are fewer peaks in the partition, trim the stressStrainPeaks array
        if (updatedStressStrainPeaks[partitionIndex].length > partition.peaks.length) {
          updatedStressStrainPeaks[partitionIndex] = updatedStressStrainPeaks[partitionIndex].slice(0, partition.peaks.length);
        }
      });

      // If there are fewer partitions, trim the stressStrainPeaks array
      if (updatedStressStrainPeaks.length > newPartitions.length) {
        updatedStressStrainPeaks = updatedStressStrainPeaks.slice(0, newPartitions.length);
      }

      return {
        ...state,
        partitions: newPartitions,
        stressPeaks: updatedStressStrainPeaks,
      };
    case SET_STRESS_STRAIN_ACTIVE_INDEX:
      return {
        ...state,
        partition: {
          ...state.partition,
          activeIndex: payload
        }
      }
    case SET_STRESS_STRAIN_SELECTING_PEAKS:
      return {
        ...state,
        partition: {
          ...state.partition,
          selectingPeaks: payload
        }
      }
    case SET_STRESS_STRAIN_HISTOGRAM:
      return {
        ...state,
        histogram: payload
      }
    case SET_STRESS_STRAIN_PARAMETERS:
      return {
        ...state,
        fitting: {
          ...payload
        }
      }
    case SET_STRESS_STRAIN_FITTING:
      return {
        ...state,
        partitionFitting: {
          [payload.file]: payload.data
        }
      }
    case SET_STRESS_STRAIN_CIF_FILES_ABC:
      return {
        ...state,
        cifFilesABC: {
          ...payload
        }
      }
    case SET_STRESS_STRAIN_ROTATION_X:
      return {
        ...state,
        rotation: {
          ...state.rotation,
          x: payload
        }
      }
    case SET_STRESS_STRAIN_ROTATION_Y:
      return {
        ...state,
        rotation: {
          ...state.rotation,
          y: payload
        }
      }
    case SET_STRESS_STRAIN_ROTATION_Z:
      return {
        ...state,
        rotation: {
          ...state.rotation,
          z: payload
        }
      }
    case SET_STRESS_STRAIN_STRESS_PEAKS:
      return {
        ...state,
        stressPeaks: [...payload]
      }
    case SET_STRESS_STRAIN_PLANE_STRESS_CONDITION:
      return {
        ...state,
        planeStressCondition: payload
      }
    case SET_STRESS_STRAIN_PARAMETERS_STRESS_BIN:
      return {
        ...state,
        stressFitting: {
          ...payload
        }
      }
    case SET_STRESS_STRAIN_PARAMETERS_RESULT:
      return {
        ...state,
        stressResult: {
          ...payload
        }
      }
    default:
      return state;
  }
}
