// SETTERS
export const SET_STRESS_STRAIN_DATA_FILES = "SET_STRESS_STRAIN_DATA_FILES";
export const SET_STRESS_STRAIN_DATA_VIEW_FILE = "SET_STRESS_STRAIN_DATA_VIEW_FILE"
export const SET_STRESS_STRAIN_DATA_ACTIVE_FILES = "SET_STRESS_STRAIN_DATA_ACTIVE_FILES";

export const SET_STRESS_STRAIN_CIF_FILES = "SET_STRESS_STRAIN_CIF_FILES";
export const SET_STRESS_STRAIN_CIF_TEMPLATE_FILES = "SET_STRESS_STRAIN_CIF_TEMPLATE_FILES";
export const SET_STRESS_STRAIN_CIF_FILES_ABC = "SET_STRESS_STRAIN_CIF_FILES_ABC";

export const SET_STRESS_STRAIN_INSTRUMENTAL_FILE = "SET_STRESS_STRAIN_INSTRUMENTAL_FILE";
export const SET_STRESS_STRAIN_INSTRUMENTAL_TEMPLATE_FILE = "SET_STRESS_STRAIN_INSTRUMENTAL_TEMPLATE_FILE";

export const SET_STRESS_STRAIN_WAVELENGTH = "SET_STRESS_STRAIN_WAVELENGTH";
export const SET_STRESS_STRAIN_INSTRUMENTAL_WAVELENGTH = "SET_STRESS_STRAIN_INSTRUMENTAL_WAVELENGTH";

export const SET_STRESS_STRAIN = "SET_STRESS_STRAIN"
export const SET_STRESS_STRAIN_RESET = "SET_STRESS_STRAIN_RESET"

export const SET_STRESS_STRAIN_PARTITIONS = "SET_STRESS_STRAIN_PARTITIONS"

export const SET_STRESS_STRAIN_RESULT = "SET_PHASE_QUANTIFICATION_RESULT"

export const SET_STRESS_STRAIN_ACTIVE_INDEX = "SET_STRESS_STRAIN_ACTIVE_INDEX"
export const SET_STRESS_STRAIN_SELECTING_PEAKS = "SET_STRESS_STRAIN_SELECTING_PEAKS"

export const SET_STRESS_STRAIN_HISTOGRAM = "SET_STRESS_STRAIN_HISTOGRAM"

export const SET_STRESS_STRAIN_PARTITION_FITTING = "SET_STRESS_STRAIN_PARTITION_FITTING"

export const SET_STRESS_STRAIN_PARAMETERS = "SET_STRESS_STRAIN_PARAMETERS"
export const SET_STRESS_STRAIN_FITTING = "SET_STRESS_STRAIN_FITTING"
export const SET_STRESS_STRAIN_PARAMETERS_STRESS_BIN = "SET_STRESS_STRAIN_PARAMETERS_STRESS_BIN"
export const SET_STRESS_STRAIN_PARAMETERS_RESULT = "SET_STRESS_STRAIN_PARAMETERS_RESULT"

export const SET_STRESS_STRAIN_ROTATION_X = "SET_STRESS_STRAIN_ROTATION_X"
export const SET_STRESS_STRAIN_ROTATION_Y = "SET_STRESS_STRAIN_ROTATION_Y"
export const SET_STRESS_STRAIN_ROTATION_Z = "SET_STRESS_STRAIN_ROTATION_Z"

export const SET_STRESS_STRAIN_STRESS_PEAKS = "SET_STRESS_STRAIN_STRESS_PEAKS"

export const SET_STRESS_STRAIN_PLANE_STRESS_CONDITION = "SET_STRESS_STRAIN_PLANE_STRESS_CONDITION"

// GETTERS
export const GET_STRESS_STRAIN = "GET_STRESS_STRAIN";
export const GET_STRESS_STRAIN_CALLBACK = "GET_STRESS_STRAIN_CALLBACK"
export const GET_STRESS_STRAIN_CALLBACK_RESULT = "GET_STRESS_STRAIN_CALLBACK_RESULT"
export const GET_STRESS_STRAIN_CALLBACK_FITTING = "GET_STRESS_STRAIN_CALLBACK_FITTING"
