import { all, put, select, takeLatest } from "redux-saga/effects";
import { SET_INSTANCE_ID, SET_IN_INLINE_OPERATION_IN_PROGRESS, SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, SET_PROGRESS, SET_PROGRESS_MESSAGE } from "../general/generalActions";
import { waitFiveSeconds } from "../../helpers/redux";
import toast from "react-hot-toast";
import { selectUserToken, selectCurrentProject } from "../project/projectSelectors";
import { StressStrainModel } from "../../models/analysis.stressStrain.model";
import { GET_STRESS_STRAIN, GET_STRESS_STRAIN_CALLBACK, GET_STRESS_STRAIN_CALLBACK_FITTING, GET_STRESS_STRAIN_CALLBACK_RESULT, SET_STRESS_STRAIN_FITTING, SET_STRESS_STRAIN_PARAMETERS, SET_STRESS_STRAIN_PARAMETERS_RESULT, SET_STRESS_STRAIN_PARAMETERS_STRESS_BIN } from "./stressStrainActions";
import { getStressStrain, getStressStrainCallback, getStressStrainCallbackFitting, getStressStrainCallbackResult, preProcessObject, remapObject, remapObjectParams } from "./stressStrainHelpers";
import { selectStressStrain } from "./stressStrainSelectors";


function* get_stress_strain() {
  try {
    toast.promise(waitFiveSeconds(), {
      loading: "Starting Computing Process",
      success: "Running Stress Strain Analysis...",
      error: "Running Stress Strain Analysis...",
    })

    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let stressStrain: StressStrainModel = yield select(selectStressStrain);
    stressStrain = preProcessObject(stressStrain);
    
    const res = yield getStressStrain(
      token,
      {
        ...stressStrain,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_INSTANCE_ID, payload: res.data.instanceId });
  } catch (error) {
    console.error(error)
    toast.error("Failed to perform stress analysis. Please try again.")
  } finally {
    yield put({ type: SET_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* get_stress_strain_callback() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let stressStrain: StressStrainModel = yield select(selectStressStrain);
    stressStrain = preProcessObject(stressStrain);

    const res = yield getStressStrainCallback(
      token,
      {
        ...stressStrain,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_STRESS_STRAIN_PARAMETERS, payload: res.data.parametersData });
    yield put({ type: SET_STRESS_STRAIN_PARAMETERS_STRESS_BIN, payload: remapObject(res.data.parametersDataStressStrain ) });
  } catch (error) {
    console.error(error)
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

function* get_stress_strain_callback_result() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let stressStrain: StressStrainModel = yield select(selectStressStrain);
    stressStrain = preProcessObject(stressStrain);

    const res = yield getStressStrainCallbackResult(
      token,
      {
        ...stressStrain,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_STRESS_STRAIN_PARAMETERS_RESULT, payload: remapObjectParams(res.data) })
  } catch (error) {
    console.error(error)
    toast.error("Failed to fetch stress analysis results. Please try again.")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

function* get_stress_strain_callback_fitting() {
  try {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: true });

    const token = yield select(selectUserToken);
    const currentProject = yield select(selectCurrentProject);
    let stressStrain: StressStrainModel = yield select(selectStressStrain);
    stressStrain = preProcessObject(stressStrain);

    const res = yield getStressStrainCallbackFitting(
      token,
      {
        ...stressStrain,
        userId: currentProject.userId,
        projectId: currentProject.projectId,
      }
    );

    yield put({ type: SET_STRESS_STRAIN_FITTING, payload: { data: res.data.fittedData, file: stressStrain.dataViewFile }});
  } catch (error) {
    console.error(error)
    toast.error("Failed to fetch stress analysis results. Please try again.")
  } finally {
    yield put({ type: SET_IN_IN_INLINE_OPERATION_IN_PROGRESS, payload: false });
    yield put({ type: SET_INSTANCE_ID, payload: "" })
    yield put({ type: SET_PROGRESS, payload: 0 })
    yield put({ type: SET_PROGRESS_MESSAGE, payload: "Starting Instance" })
  }
}

export default function* stressStrainSaga() {
  yield all([
    takeLatest(GET_STRESS_STRAIN_CALLBACK, get_stress_strain_callback),
    takeLatest(GET_STRESS_STRAIN_CALLBACK_FITTING, get_stress_strain_callback_fitting),
    takeLatest(GET_STRESS_STRAIN_CALLBACK_RESULT, get_stress_strain_callback_result),
    takeLatest(GET_STRESS_STRAIN, get_stress_strain),
  ]);
}
